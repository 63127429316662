exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-customer-idea-form-jsx": () => import("./../../../src/pages/customer-idea-form.jsx" /* webpackChunkName: "component---src-pages-customer-idea-form-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects/[...].js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-solutions-jsx": () => import("./../../../src/pages/solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

