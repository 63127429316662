import { createSlice } from '@reduxjs/toolkit'
import isBrowser from '../../util/isBrowser';
const mode = "dark";

if (isBrowser()) document.body.classList.add("mode_dark");


const modeSlice = createSlice({
    name: "mode",
    initialState: { mode },
    reducers: {
        setMode(state, action) {
            document.body.classList.remove("mode_" + state.mode)
            document.body.classList.add("mode_" + action.payload);
            state.mode = action.payload;
        }
    }
})

export const { setMode } = modeSlice.actions;
export const getMode = (state) => state.mode.mode;
export default modeSlice.reducer;