import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiCustomers } from '../../config/api/endpoints/customers'

export const fetchCustomer = createAsyncThunk("customer/fetchCustomer ", async () => {
    const response = await apiCustomers.getAllCustomers();
    return response.data.data;
})
const customer = createSlice({
    name: "customer",
    initialState: {
        customers: [],
        loading: "idle",
    },
    extraReducers: {
        [fetchCustomer.pending]: (state, action) => {
            state.loading = "pending";
        },
        [fetchCustomer.fulfilled]: (state, action) => {
            state.loading = "success";
            state.customers = action.payload;
        },
        [fetchCustomer.rejected]: (state, action) => {
            state.loading = "error";
        }
    }
})

export const selectCustomers = (state) => state.customer;
export default customer.reducer;