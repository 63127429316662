import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGlobal } from '../../config/api/endpoints/global';

export const fetchGlobalData = createAsyncThunk("global_data/fetchEntities ", async (lang) => {
    const response = await apiGlobal.getGlobalData(lang);
    return response.data.data.attributes;
})
const global_data = createSlice({
    name: "global_data",
    initialState: {
        entities: null,
        loading: "idle",
        pageLoaded: false
    },
    extraReducers: {
        [fetchGlobalData.pending]: (state) => {
            state.loading = "pending";
        },
        [fetchGlobalData.fulfilled]: (state, action) => {
            state.loading = "success";
            state.entities = action.payload;
        },
        [fetchGlobalData.rejected]: (state) => {
            state.loading = "error";
        }
    },
    reducers: {
        setPageLoaded(state, action) {
            state.pageLoaded = action.payload;
        }
    }
})

export const selectGlobalData = (state) => state.global_data;
export const selectPageLoaded = (state) => state.global_data.pageLoaded;
export const { setPageLoaded } = global_data.actions;
export default global_data.reducer;