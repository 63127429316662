import { createSlice } from '@reduxjs/toolkit'

const languageSlice = createSlice({
    name: "language",
    initialState: { lang: "en" },
    reducers: {
        setLanguage(_, action) {
            if (["en", "az", "ru"].includes(action.payload)) {
                localStorage.setItem("csoft", action.payload);
                window.location.reload();
            }
        }
    }
})

export const { setLanguage } = languageSlice.actions;
export const getLanguage = (state) => {
    return state.language.lang;
};
export default languageSlice.reducer;