import { instagram } from "./axios"

export const instagramPosts = {
    getInstaPosts: () => instagram.get(`/${process.env.GATSBY_INSTAGRAM_USER_ID}/media`, {
        params: {
            fields: "id,timestamp,media_url,caption",
            access_token: process.env.GATSBY_INSTAGRAM_TEMP_ACCESS_TOKEN
        }
    })

}