import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiHomepage } from '../../config/api/endpoints/homepage';

export const fetchHomepageEntities = createAsyncThunk("home_page/fetchEntities ", async (lang) => {
    const response = await apiHomepage.getHomepageContent(lang);
    return response.data.data.attributes;
})
const home_page = createSlice({
    name: "home_page",
    initialState: {
        entities: null,
        loading: "idle",
    },
    extraReducers: {
        [fetchHomepageEntities.pending]: (state) => {
            state.loading = "pending";
        },
        [fetchHomepageEntities.fulfilled]: (state, action) => {
            state.loading = "success";
            state.entities = action.payload;
        },
        [fetchHomepageEntities.rejected]: (state) => {
            state.loading = "error";
        }
    }
})

export const selectHomepageEntities = (state) => state.home_page;
export default home_page.reducer;