import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiMembers } from '../../config/api/endpoints/members'

export const fetchMembers = createAsyncThunk("members/fetchMembers", async () => {
    const response = await apiMembers.getAllMembers();
    return response.data.data;
})
const memberSlice = createSlice({
    name: "member",
    initialState: {
        customers: [],
        loading: "idle" | "pending" | "success" | "error",
    },
    extraReducers: {
        [fetchMembers.pending]: (state, action) => {
            state.loading = "pending";
        },
        [fetchMembers.fulfilled]: (state, action) => {
            state.loading = "success";
            state.customers = action.payload;
        },
        [fetchMembers.rejected]: (state, action) => {
            state.loading = "error";
        }
    }
})

export const selectMembers = (state) => state.member;
export default memberSlice.reducer;