import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiSolutions } from '../../config/api/endpoints/solutions';

export const fetchSolutions = createAsyncThunk("solutions/fetchSolutions", async () => {
    const response = await apiSolutions.getAllSolutions();
    return response.data.data;
})
const solutionSlice = createSlice({
    name: "solution",
    initialState: {
        solutions: [],
        loading: "idle",
    },
    extraReducers: {
        [fetchSolutions.pending]: (state, action) => {
            state.loading = "pending";
        },
        [fetchSolutions.fulfilled]: (state, action) => {
            state.loading = "success";
            state.solutions = action.payload;
        },
        [fetchSolutions.rejected]: (state, action) => {
            state.loading = "error";
        }
    }
})

export const getSolutions = (state) => state.solution;
export default solutionSlice.reducer;