import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiAbout } from '../../config/api/endpoints/about';

export const fetchAboutEntities = createAsyncThunk("about_page/fetchEntities ", async (lang) => {
    const response = await apiAbout.getAboutContent(lang);
    return response.data.data.attributes;
})
const about_page = createSlice({
    name: "about_page",
    initialState: {
        entities: null,
        loading: "idle" | "pending" | "success" | "error",
    },
    extraReducers: {
        [fetchAboutEntities.pending]: (state) => {
            state.loading = "pending";
        },
        [fetchAboutEntities.fulfilled]: (state, action) => {
            state.loading = "success";
            state.entities = action.payload;
        },
        [fetchAboutEntities.rejected]: (state) => {
            state.loading = "error";
        }
    }
})

export const selectAboutPage = (state) => state.about_page;
export default about_page.reducer;