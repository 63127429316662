import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiProducts } from '../../config/api/endpoints/products';

export const fetchProducts = createAsyncThunk("product/fetchProducts ", async () => {
    const response = await apiProducts.getAllProducts();
    return response.data.data;
})
const product = createSlice({
    name: "product",
    initialState: {
        products: [],
        loading: "idle",
    },
    extraReducers: {
        [fetchProducts.pending]: (state, action) => {
            state.loading = "pending";
        },
        [fetchProducts.fulfilled]: (state, action) => {
            state.loading = "success";
            state.products = action.payload;
        },
        [fetchProducts.rejected]: (state, action) => {
            state.loading = "error";
        }
    }
})

export const selectProducts = (state) => state.product;
export default product.reducer;