import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { instagramPosts } from '../../config/instagram/enpoints';

export const fetchPosts = createAsyncThunk("insta/fetchPosts ", async () => {
    const response = await instagramPosts.getInstaPosts();
    return response.data.data;
})
const insta = createSlice({
    name: "insta_posts",
    initialState: {
        posts: [],
        loading: "idle" | "pending" | "success" | "error",
    },
    extraReducers: {
        [fetchPosts.pending]: (state) => {
            state.loading = "pending";
        },
        [fetchPosts.fulfilled]: (state, action) => {
            state.loading = "success";
            state.posts = action.payload;
        },
        [fetchPosts.rejected]: (state) => {
            state.loading = "error";
        }
    }
})

export const selectInstaPosts = (state) => state.insta;
export default insta.reducer;