import { configureStore } from '@reduxjs/toolkit'
import aboutPageSlice from './slices/aboutPageSlice';
import customerSlice from './slices/customerSlice';
import homePageSlice from './slices/homePageSlice';
import instaSlice from './slices/instaSlice';
import languageSlice from './slices/languageSlice'
import memberSlice from './slices/memberSlice';
import modeSlice from './slices/modeSlice';
import globalSlice from './slices/globalSlice';
import solutionsSlice from './slices/solutionsSlice';
import productsSlice from './slices/productsSlice';

const initStore = configureStore({
    reducer: {
        language: languageSlice,
        mode: modeSlice,
        member: memberSlice,
        customer: customerSlice,
        product: productsSlice,
        global_data: globalSlice,
        insta: instaSlice,
        solution: solutionsSlice,
        home_page: homePageSlice,
        about_page: aboutPageSlice
    }
})

const store = () => initStore

export default store;